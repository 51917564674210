import { FieldAttribute } from '@app/additional-fields/interfaces/field-attribute';
import { DeliveryType } from '@app/orders/enums/delivery-type';
import { OrderOfferDocument } from '@app/shared-widgets/interfaces/order-offer-document.interface';
import {
    FinancialData,
    RealtimeFinancialData,
} from '@core/interfaces/financial-data.interface';
import { HasMany } from '@core/relations/has-many';
import { HasOne } from '@core/relations/has-one';
import { Audit } from './audit';
import { Company } from './company';
import { InsuranceRate } from './insurance-rate';
import { JobBikeProcessFlow } from './job-bike-process-flow.interface';
import { Leasing } from './leasing';
import { LeasingCondition } from './leasing-condition';
import { Model } from './model';
import { OfferAccessory } from './offer-accessory';
import { Product } from './product';
import { ProductCategory } from './product-category';
import { ServiceRate } from './service-rate';
import { Status } from './status';
import { Supplier } from './supplier';
import { User } from './user';
import { Vat } from './vat';

export enum OfferStatus {
    Rejected = 9,
    Accepted = 10,
    Pending = 11,
    PendingCompanyApproval = 18,
    Draft = 19,
    Uev_signed = 20,
    TemproraryRejected = 36,
}

@HasOne(LeasingCondition, 'leasingCondition')
@HasOne(Company, 'company')
@HasOne(User, 'employee')
@HasOne(ServiceRate, 'serviceRate')
@HasOne(InsuranceRate, 'insuranceRate')
@HasMany(ServiceRate, 'serviceRates')
@HasMany(Audit, 'audits')
@HasMany(InsuranceRate, 'insuranceRates')
export class Offer extends Model implements Leasing {
    public number: string;
    public id: number;
    public contract_file: string;
    public contract_generated: boolean;
    public senderName: string;
    public senderId: number;
    public product: Product;
    public company: Company;
    public employee: User;

    public supplierCode: string;
    public supplierId: number;
    public supplierName: string;
    public supplierStreet: string;
    public supplierPostalCode: string;
    public supplierCity: string;
    public subSupplierId: number;
    public subSupplierName: string;
    public supplierUserID: string;

    public employeeNumber: string;
    public employeeCostCenter: string;
    public employeeFrontName: string;
    public employeeName: string;
    public employeeCode: string;
    public employeeSalutation: string;
    public employeeStreet: string;
    public employeePostalCode: string;
    public employeeCity: string;
    public employeeEmail: string;
    public employeePhone: string;
    public costCentre: string;

    public productCategory: ProductCategory;
    public productBrand: string;
    public productModel: string;
    public productColor: string;
    public productSize: string;

    public taxRate: number;
    public vat: Vat;
    public invoiceType: string;
    public insuranceRateAmount: number;
    public productListPrice: number;
    public productDiscountedPrice: number;
    public productDiscount: number;
    public accessoriesPrice: number;
    public accessoriesDiscountedPrice: number;
    public insurance_rate_name: string;
    public service_rate_name: string;
    public blindDiscountAmount: number;
    public sku: string;

    public leasingCondition: LeasingCondition;
    public insuranceRates: InsuranceRate[];
    public serviceRates: ServiceRate[];
    public insuranceRate?: InsuranceRate;
    public serviceRate?: ServiceRate;
    public agreedPurchasePrice: number;
    public apartmentWorkplaceDistance: number;
    public audits: Audit[];
    public offerPdf: string;
    public notes;
    public status_id;
    public status: Status;
    public expiryDate: string;
    public deliveryDate: string;
    public deliveryPlanned: string;
    public createdAt: string;
    public leasing_period: number;
    public accessories: OfferAccessory[];
    public govecsDeliveryType: DeliveryType;
    public homeDeliveryDestination: string;
    public parentSupplier: Supplier;
    public homeOfficeTravelTax: number;
    public cancelled_at: string;
    public cancellationReason: string;
    public can_be_cancelled: boolean;
    public realtime_financial_data: RealtimeFinancialData;
    public financial_data: FinancialData;
    public display_financial_data: RealtimeFinancialData;
    public no_cancel_credit_financial_data: RealtimeFinancialData;
    public cancel_credit_list: {
        id: number;
        number: string;
        cancel_credit: number;
    }[];
    public replace_contract_id: number;
    public contract_document: OrderOfferDocument;
    public signed_contract_document: OrderOfferDocument;
    public employeeAttributes: FieldAttribute[] = [];
    public reference: string;
    public selectedSubsidy: string;
    public selectableSubsidies: string[];

    public getAccessories(): OfferAccessory[] {
        return this.accessories;
    }
    public firstApprovalAdminId: number;
    public jobBikeProcessFlow?: JobBikeProcessFlow[];
}
