import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { FileType } from '../../enums/file-type';
import { FileHelper } from '../../helpers/file-helper';
import { FileUploadConfig } from '../../interfaces/file-upload-config';

export const DEFAULT_CONFIG: Required<FileUploadConfig> = {
    text: 'GENERIC.FILE_UPLOAD.DESCRIPTION',
    multiple: false,
    acceptedFileTypes: [FileType.PDF],
    maxFileSizeInMb: 4,
};

@Component({
    selector: 'dd-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent {
    @Input() public formFieldId: string;
    @Input() public set config(config: FileUploadConfig) {
        if (config) {
            this._config = { ...DEFAULT_CONFIG, ...config };
        }
    }

    public get config(): FileUploadConfig {
        return this._config;
    }

    @Output() public readonly handleFileInput = new EventEmitter<File[]>();

    private _config = DEFAULT_CONFIG;

    constructor(
        private toastr: ToastrService,
        private translateService: TranslateService,
    ) {}

    public onFileInput(files: FileList): void {
        const validFiles = this.checkFileSize(Array.from(files));

        this.handleFileInput.emit(validFiles);
    }

    private checkFileSize(files: File[]): File[] {
        const validFiles: File[] = [];
        const invalidFiles: File[] = [];

        files.forEach((file) =>
            (FileHelper.exceedsFileSize(file, this.config.maxFileSizeInMb)
                ? invalidFiles
                : validFiles
            ).push(file),
        );

        invalidFiles.forEach((file) =>
            this.toastr.error(
                this.translateService.instant(
                    'GENERIC.FILE_UPLOAD.FILESIZE_EXCEEDED_ERROR.DESCRIPTION',
                    {
                        fileName: file.name,
                        maxFileSize: this.config.maxFileSizeInMb,
                    },
                ),
                this.translateService.instant(
                    'GENERIC.FILE_UPLOAD.FILESIZE_EXCEEDED_ERROR.TITLE',
                ),
            ),
        );

        return validFiles;
    }
}
