export class ApplicationError {
    constructor(public message: string) {}
}

export class ServerError extends ApplicationError {
    constructor() {
        super('ERROR.TRY_AGAIN_LATER');
    }
}

export class ForbiddenError extends ApplicationError {
    constructor() {
        super('ERROR.NOT_ALLOWED');
    }
}
