import { CommonModule } from '@angular/common';
// Services
import { LayoutModule } from '@angular/cdk/layout';
import {
    provideHttpClient,
    withInterceptorsFromDi,
} from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AUTH_SERVICE, AuthModule } from 'ngx-auth';
import { CookieService } from 'ngx-cookie-service';
import { MenuService } from '../layout/sidebar/menu.service';
import { FormUtilities } from './forms/form-utilities';
import { AuthenticationModule } from './services/auth/auth.module';
import { AuthenticationService } from './services/auth/auth.service';
import { BreakpointService } from './services/breakpoint/breakpoint.service';

@NgModule({
    imports: [CommonModule, AuthenticationModule, AuthModule, LayoutModule],
    providers: [FormUtilities, provideHttpClient(withInterceptorsFromDi())],
})
export class CoreModule {
    public static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                CookieService,
                MenuService,
                BreakpointService,
                {
                    provide: AUTH_SERVICE,
                    useClass: AuthenticationService,
                },
            ],
        };
    }
}
