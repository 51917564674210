import {
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'mrc-alert-modal',
    styleUrls: ['./alert-modal.component.scss'],
    templateUrl: './alert-modal.component.html',
    exportAs: 'alertModal',
})
export class AlertModalComponent<T> {
    @Input() public title: string;
    @Input() public buttonText: string;
    @Input() public closeButton = false;

    @ViewChild('template') public template: TemplateRef<any>;

    @Output() public accepted: EventEmitter<T> = new EventEmitter();

    public subject: T;
    private openedModal: BsModalRef;

    constructor(public modalService: BsModalService) {}

    public show(subject?: T): void {
        this.openedModal = this.modalService.show(this.template, {
            ignoreBackdropClick: true,
        });
        this.subject = subject;
    }

    public accept(): void {
        this.accepted.emit(this.subject);
        this.openedModal.hide();
    }
}
