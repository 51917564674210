<div
    class="loading-overlay"
    *ngIf="loading || loadingRoute"
>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    <div
        class="loading"
        *ngIf="firstLoading"
    >
        <img
            src="../assets/images/fahrrad-icon-ladebildschirm.png"
            alt=""
        />
        <span translate>GENERIC.LABELS.LOADING</span>
    </div>
</div>
<router-outlet></router-outlet>
