import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
    selector: 'dd-info-tooltip',
    standalone: true,
    imports: [CommonModule, MatTooltipModule],
    templateUrl: './info-tooltip.component.html',
    styleUrls: ['./info-tooltip.component.scss'],
})
export class InfoTooltipComponent {
    @Input({ required: true }) public text: string;
    @Input() public whiteIcon: boolean = false;
}
