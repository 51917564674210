import { AdditionalField } from '@app/additional-fields/interfaces/additional-field';
import { CoverageType } from '@app/individual-settings/enums/coverage-type';
import { OfferAccessoryCategory } from '@app/offers/interfaces/offer-accessory-category.interface';
import { Mixin } from '@common/mixin';
import { CompanyPolicy } from '@companies/interfaces/company-policy';
import { TransferContractSummaryType } from '@companies/types/transfer-contract-summary.type';
import { ContractCondition } from '@contracts/interfaces/contract-condition';
import { OpenIdConfiguration } from '@models/open-id-configuration';
import { ServiceRate } from '@models/service-rate';
import { Bank } from './bank';
import { CityInterface } from './city.interface';
import { CompanyEditsPermissions } from './company-edits-permissions';
import { CompanyQuestion } from './company-question';
import { HasLeasingSettings } from './has-leasing-settings';
import { Status } from './status';
import { Subsidies } from './subsidy';
import { User } from './user';

export enum CompanyStatus {
    NotCreated = 0,
    Active = 5,
    Inactive = 6,
}

export enum InvoiceType {
    GROSS = 'gross',
    NET = 'net',
}

export type GrossConversion = 'brutto' | 'netto';

interface CompanyMixin extends HasLeasingSettings, CompanyEditsPermissions {}

export class Company extends Mixin<CompanyMixin>(
    HasLeasingSettings,
    CompanyEditsPermissions,
) {
    public id: number;
    public address: string;
    public admins: User[];
    public city: CityInterface;
    public code: string;
    public name: string;
    public zip: string;
    public vat: string;
    public invoice_type: InvoiceType = InvoiceType.NET;
    public admin_email: string;
    public supplier_ids: number[] = [];
    public subcompany_ids: any[] = [];
    public city_id: number;
    public city_name: string;
    public phone: string;
    public max_user_contracts: number;
    public max_user_amount: number;
    public min_user_amount: number;
    public is_max_user_amount_uvp: boolean;
    public max_user_amount_text: string;
    public insurance_covered: boolean;
    public insurance_covered_type: CoverageType;
    public insurance_covered_amount: number;
    public maintenance_covered: boolean;
    public maintenance_covered_type: CoverageType;
    public maintenance_covered_amount: number;
    public leasing_rate: boolean;
    public leasing_rate_type: CoverageType;
    public leasing_rate_amount: number;
    public leasing_budget: number;
    public spent_budget: number;
    public spentBudgetCurrentCompany: number;
    public spentGroupBudget: number;
    public slug: string;
    public status: Status;
    public logo: string;
    public is_accept_employee: boolean;
    public s_pedelec_disable: boolean;
    public end_contract: string;
    public gross_conversion: GrossConversion;
    public include_service_rate: boolean;
    public include_insurance_rate: boolean;
    public boni_number: string;
    public gp_number: string;
    public prv_number: string;
    public parent_company_id: number;
    public parentCompany: Company;
    public required_personal_number: boolean;
    public required_cost_centre: boolean;
    public required_register_by_domain: boolean;
    public product_category_ids: number[];
    public parent_company_name: string;
    // TODO (https://app.clickup.com/t/86c0cnzyk): Can be removed once the leasing_budget automatically returns the budge of the parentCompany, when isGroupLeasingBudget is true
    public parentCompanyLeasingBudget: number;
    public parent_company_slug: string;
    public block_leasing_rate: boolean;
    public block_insurance_rate: boolean;
    public block_service_rate: boolean;
    public enable_partner: boolean;
    public netto_provision: number;
    public provision_mode: CoverageType;
    public ddpartner_id: number;
    public ddpartner_name_id: number;
    public subsidies: Subsidies;
    public portal_id: number;
    public enable_digital_signature: boolean;
    public enable_employee_registration: boolean;
    public questions: CompanyQuestion[];
    public enable_bikesde: boolean;
    public total_employees: number;
    public total_employees_authorized: number;
    public sales_consultant_id: number;
    public onboarding_consultant_id: number;
    public ddberater_id: number;
    public vip_customer: boolean;
    public bank_id: number;
    public bankId: number; // Now: only for contract replacement, target/best practice: for all endpoints
    public bank: Bank;
    public show_beamte_calculator: boolean;
    public allow_public_registration: boolean;
    public beamte_calculator: boolean;
    public blacklisted_domains: string[] = [];
    public service_data: any;
    public insurance_data: any;
    public leasing_data: any;
    public offer_contract_file_auto_approve: boolean;
    public transfer_contract_summary: {
        [key in TransferContractSummaryType]: boolean;
    };
    public elv_els_limit: number;
    public additional_fields: AdditionalField[] = [];
    public offerAccessoryCategories: OfferAccessoryCategory[] = [];
    public faqLink: string;
    public ddContact: boolean;
    public max_conversion_amount: number;
    public is_max_conversion_amount_active = false;
    public send_notification_emails: boolean;
    public homepageDescription: string;
    public openIdConfiguration?: OpenIdConfiguration;
    public isListApproval = false;
    public listApprovalAttributeField = '';
    public isSecondAdminApprovalActive: boolean;
    public isAcceptWithConditionsActive: boolean;
    public conditionsDescription: string;
    public conditions: ContractCondition[];
    public policy?: CompanyPolicy;
    public isLanguageToggleActive: boolean;
    public externalApproveOfferContext: boolean;
    public showMaxUserContracts: boolean;
    public isRemoveUserOnPolicyReject: boolean;
    public showMinLockValue: boolean;
    public serviceRates: ServiceRate[];
    public isMfaActive: boolean;
    public employee_count: number;
    public enable_individual_contracts: boolean;
    public isGroupLeasingBudget: boolean;
    public hasVUWithGroupLeasingBudget: boolean;
    public afterSalesConsultantId: number;
    public subsidyMode: string;
    public insuranceCoveredSecondBike: boolean;
    public insuranceCoveredTypeSecondBike: CoverageType;
    public insuranceCoveredAmountSecondBike: number;
    public blockInsuranceRateSecondBike: boolean;
    public maintenanceCoveredSecondBike: boolean;
    public maintenanceCoveredTypeSecondBike: CoverageType;
    public maintenanceCoveredAmountSecondBike: number;
    public blockServiceRateSecondBike: boolean;
    public leasingRateSecondBike: boolean;
    public leasingRateTypeSecondBike: CoverageType;
    public leasingRateAmountSecondBike: number;
    public blockLeasingRateSecondBike: boolean;

    public isAnyPropertyActive(props: string[]): boolean {
        let isOneActive = false;
        props.forEach((prop) => {
            if (typeof this[prop] == 'object') {
                for (const key in this[prop]) {
                    if (this[prop][key]) {
                        isOneActive = true;
                        break;
                    }
                }
            } else if (this[prop] !== undefined) {
                isOneActive = this[prop];
            }
        });
        return isOneActive;
    }
}
