import {
    AbstractControl,
    FormArray,
    FormControl,
    FormGroup,
} from '@angular/forms';

export class FormHelper {
    /**
     * Takes a {@link FormControl}, (nested) {@link FormGroup} or {@link FormArray} and marks every control dirty.
     * @param abstractControl {@link AbstractControl} {@link FormControl}, (nested) {@link FormGroup} or {@link FormArray} to validate
     */
    public static markAllAsDirty(abstractControl: AbstractControl): void {
        if (abstractControl instanceof FormControl) {
            this.markFormControlAsDirty(abstractControl);
        } else if (abstractControl instanceof FormGroup) {
            this.markFormGroupAsDirty(abstractControl);
        } else if (abstractControl instanceof FormArray) {
            this.markFormArrayAsDirty(abstractControl);
        }
    }

    /**
     * Tries to retrieve a control with the given {@link key} in the passed {@link container}. If it cannot be found and the container
     * has a parent which is a {@link FormGroup} the control will be searched for in that parent (and so on).
     * @param {AbstractControl} container Container to look for control
     * @param {string} key Key used to retrieve the control
     * @returns {AbstractControl | null} Found control or null if control could not be found
     */
    public static findControlUpwards(
        container: AbstractControl,
        key: string,
    ): AbstractControl | null {
        let control = container.get(key);

        if (!control && container.parent instanceof FormGroup) {
            control = FormHelper.findControlUpwards(container.parent, key);
        }

        return control ?? null;
    }

    private static markFormGroupAsDirty(formGroup: FormGroup): void {
        Object.keys(formGroup.controls).forEach((key) => {
            const control = formGroup.get(key);

            if (control instanceof FormControl) {
                this.markFormControlAsDirty(control);
            } else if (control instanceof FormGroup) {
                this.markFormGroupAsDirty(control);
            } else if (control instanceof FormArray) {
                this.markFormArrayAsDirty(control);
            }
        });
    }

    private static markFormArrayAsDirty(formArray: FormArray): void {
        formArray.controls.forEach((control) => {
            if (control instanceof FormControl) {
                this.markFormControlAsDirty(control);
            } else if (control instanceof FormGroup) {
                this.markFormGroupAsDirty(control);
            }
        });
    }

    private static markFormControlAsDirty(control: FormControl): void {
        control.markAsDirty();
    }
}
