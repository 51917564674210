<div class="layout-unauthorized">
    <div class="container">
        <div class="row min-vh-100 align-items-center">
            <div class="col-12">
                <div class="row justify-content-center">
                    <div class="col-12 col-sm-6">
                        <div class="card">
                            <div class="card-body">
                                <div class="logo">
                                    <img
                                        src="./assets/images/logos/dd-logo.png"
                                        alt=""
                                    />
                                </div>
                                <div class="row justify-content-center">
                                    <h1
                                        class="title text-start col-sm-8"
                                        translate
                                    >
                                        PASSWORD.FORGOT_PASSWORD_TITLE
                                    </h1>
                                    <p
                                        class="title-description col-sm-8"
                                        *ngIf="submitted === false"
                                        translate
                                    >
                                        PASSWORD.FORGOT_PASSWORD_TEXT
                                    </p>
                                    <form
                                        *ngIf="submitted === false"
                                        class="col-sm-8"
                                        [formGroup]="forgotPasswordForm"
                                        (ngSubmit)="forgotPassword()"
                                        ddFocusInvalidInput
                                    >
                                        <div class="mb-4">
                                            <mrc-form-item-field
                                                [label]="
                                                    'USER.EMAIL' | translate
                                                "
                                                [control]="
                                                    forgotPasswordForm.controls
                                                        .email
                                                "
                                                [id]="'email'"
                                            >
                                                <input
                                                    id="email"
                                                    type="email"
                                                    name="email"
                                                    class="form-control"
                                                    formControlName="email"
                                                    [placeholder]="
                                                        'USER.EMAIL' | translate
                                                    "
                                                />
                                            </mrc-form-item-field>
                                        </div>
                                        <div>
                                            <button
                                                type="submit"
                                                class="btn btn-primary w-100"
                                                [disabled]="
                                                    forgotPasswordForm.disabled
                                                "
                                                translate
                                            >
                                                PASSWORD.FORGOT_PASSWORD_BUTTON
                                            </button>
                                        </div>
                                    </form>

                                    <p
                                        *ngIf="submitted"
                                        class="col-sm-10 text-center my-4"
                                        translate
                                    >
                                        PASSWORD.FORGOT_PASSWORD_SUBMITTED_TEXT
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
