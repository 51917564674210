<div class="additional-field mb-2">
    <label
        [for]="field.key"
        class="additional-field__label"
        >{{ field.label }}</label
    >
    <ng-container [ngSwitch]="field.type">
        <input
            *ngSwitchCase="'text'"
            [id]="field.key"
            [formControl]="control"
            [placeholder]="field.placeholder || ''"
            class="form-control"
            type="text"
        />
        <input
            *ngSwitchCase="'number'"
            [id]="field.key"
            [formControl]="control"
            [placeholder]="field.placeholder || ''"
            class="form-control"
            type="text"
        />
        <ng-select
            *ngSwitchCase="'select'"
            [labelForId]="field.key"
            class="form-control"
            [formControl]="control"
            [items]="field.options"
            bindLabel="label"
            bindValue="value"
            [clearable]="true"
            [searchable]="false"
        ></ng-select>
    </ng-container>
    <mat-error *ngFor="let error of control?.errors | keyvalue">
        {{ 'VALIDATION.' + error.key | translate }}
    </mat-error>
</div>
