<div class="layout-unauthorized">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="logo">
                    <img
                        [src]="logoService.loginLogo"
                        alt=""
                    />
                </div>

                <div class="card">
                    <div class="card-body">
                        <div class="row justify-content-center">
                            <h1
                                class="title text-start col-sm-8"
                                translate
                            >
                                PASSWORD.FORGOT_PASSWORD_TITLE
                            </h1>
                            <p
                                class="title-description col-sm-8"
                                *ngIf="submitted === false"
                                translate
                            >
                                PASSWORD.FORGOT_PASSWORD_TEXT
                            </p>
                            <form
                                *ngIf="submitted === false"
                                class="col-sm-8"
                                [formGroup]="forgotPasswordForm"
                                (ngSubmit)="forgotPassword()"
                            >
                                <div class="mb-4">
                                    <mrc-form-item-field
                                        [label]="'USER.EMAIL' | translate"
                                        [control]="
                                            forgotPasswordForm.controls.email
                                        "
                                        [id]="'email'"
                                    >
                                        <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            class="form-control"
                                            formControlName="email"
                                            [placeholder]="
                                                'USER.EMAIL' | translate
                                            "
                                        />
                                    </mrc-form-item-field>
                                </div>
                                <div>
                                    <button
                                        type="submit"
                                        class="btn btn-primary w-100"
                                        translate
                                    >
                                        PASSWORD.FORGOT_PASSWORD_BUTTON
                                    </button>
                                </div>
                            </form>

                            <p
                                *ngIf="submitted"
                                class="col-sm-10 text-center my-4"
                                translate
                            >
                                PASSWORD.FORGOT_PASSWORD_SUBMITTED_TEXT
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
