import { FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const checkNameInPasswordValidator = (
    firstName: FormControl<string>,
    lastName: FormControl<string>,
): ValidatorFn => {
    return (control: FormControl<string>): ValidationErrors | null => {
        const password = control.value?.toLowerCase();
        const firstNameValue = firstName?.value?.toLowerCase() || '';
        const lastNameValue = lastName?.value?.toLowerCase() || '';

        if (
            (firstNameValue && password?.includes(firstNameValue)) ||
            (lastNameValue && password?.includes(lastNameValue))
        ) {
            return { passwordContainsName: true };
        }

        return null;
    };
};
