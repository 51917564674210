import { Component, EventEmitter, Output, input } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Status } from '@models/status';

@Component({
    selector: 'mrc-status-tabs',
    templateUrl: './status-tabs.component.html',
})
export class StatusTabsComponent {
    public statusList = input.required<Status[]>();
    @Output() public selectedStatus = new EventEmitter<Status>();
    private selectedTabIndex = 0;

    public changeTab(tabChanged: MatTabChangeEvent): void {
        if (tabChanged.index !== this.selectedTabIndex) {
            this.selectedTabIndex = tabChanged.index;
            this.selectedStatus.emit(
                this.statusList().at(this.selectedTabIndex),
            );
        }
    }
}
