<mat-tab-group (selectedTabChange)="changeTab($event)">
    @for (status of statusList(); track $index) {
        <mat-tab [label]="status.label">
            <ng-template mat-tab-label>
                <div class="px-3 px-md-0">
                    <span class="me-2">{{ status.label }}</span>
                    @if (status.tooltip_content) {
                        <dd-info-tooltip [text]="status.tooltip_content" />
                    }
                </div>
            </ng-template>
        </mat-tab>
    }
</mat-tab-group>
