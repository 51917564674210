import {
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FileType } from '@app/ui-component/file-upload/enums/file-type';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'mrc-image-uploader',
    templateUrl: './image-uploader.component.html',
    styleUrls: ['./image-uploader.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ImageUploaderComponent),
            multi: true,
        },
    ],
})
export class ImageUploaderComponent implements ControlValueAccessor {
    @Input() public formFieldId: string;
    @Input() public imageUrl: string;
    @Input() public alt = '';
    @Output() public update = new EventEmitter();
    @ViewChild('file') private fileElement: ElementRef;
    public imageData: string;
    private image: File;

    private onChangeFn: (val: string) => void;
    private onTouchFn: () => void;
    public isDisabled = false;

    public FileType = FileType;

    constructor(private toastrService: ToastrService) {}

    public writeValue(value: string): void {
        this.imageUrl = value;
    }

    public registerOnChange(fn: (val: string) => void): void {
        this.onChangeFn = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouchFn = fn;
    }

    public setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    public load(files: File[]): void {
        if (
            !files[0].type.includes('image') ||
            files[0].type.includes('tiff')
        ) {
            this.toastrService.error('Bitte füge das richtige Bild hinzu');
            return;
        }
        this.image = files[0];

        const reader = new FileReader();
        reader.onload = (event: ProgressEvent<FileReader>): void => {
            this.imageData = event.target.result as string;
            this.imageUrl = event.target.result as string;
            this.onChangeFn(this.imageUrl);
        };
        reader.readAsDataURL(this.image);
        this.update.emit();
    }

    public hasLogoToUpload(): boolean {
        return !!this.image || !!this.imageData;
    }

    public getFile(): File {
        return this.image;
    }

    public setImageUrl(url: string): void {
        this.remove();
        this.imageUrl = url;
    }

    private remove(): void {
        this.image = null;
        this.imageUrl = null;
        this.imageData = null;
        if (this.fileElement) {
            this.fileElement.nativeElement.value = '';
        }
        this.update.emit();
        this.onChangeFn(null);
    }
}
