import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { TokenStorageService } from '@core/services/auth/token-storage.service';
import { MODULE_ACCESS_TOKEN } from '@core/tokens/module-access-token.token';
import { MODULE_URL } from '@core/tokens/module-url.token';
import { MODULE } from '@core/tokens/module.token';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import {
    Observable,
    ObservableInput,
    catchError,
    finalize,
    map,
    mergeMap,
    of,
    throwError,
} from 'rxjs';
import { ForbiddenError } from '../../exceptions/error';
import { LoginMode } from '../auth/auth-settings.service';
import { AuthenticationService } from '../auth/auth.service';
import { LoadingService } from '../loading/loading.service';

export const SkipModuleUrl = 'X-Skip-Module-Url';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    constructor(
        private loadingService: LoadingService,
        private tokenStorageService: TokenStorageService,
        @Optional() @Inject(MODULE_ACCESS_TOKEN) private tokenKey: string,
        @Optional() @Inject(MODULE) private module: LoginMode,
        @Optional() @Inject(MODULE_URL) private url: string,
        private router: Router,
        private authService: AuthenticationService,
        private toaster: ToastrService,
        private translateService: TranslateService,
    ) {}

    public intercept(
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        if (window.location.hostname === 'pexco.meindienstrad.services') {
            const redirect =
                window.location.protocol +
                '//deutsche-dienstrad.meindienstrad.services' +
                window.location.pathname;
            window.location.href = redirect;
        }
        const requestWithHeaders = this.getRequestWithHeaders(req);
        this.loadingService.queueRequest(requestWithHeaders);
        return this.handleRequest(requestWithHeaders, next);
    }

    private handleRequest(request, handler): Observable<HttpEvent<any>> {
        return handler.handle(request).pipe(
            catchError<any, ObservableInput<any>>(this.handleError.bind(this)),
            finalize(() => {
                this.loadingService.removeFromQueue(request);
            }),
        );
    }

    private handleError(errorResponse: HttpErrorResponse): Observable<never> {
        switch (errorResponse.status) {
            case 403:
                return this.handleForbiddenError(errorResponse).pipe(
                    mergeMap((error) => throwError(() => error)),
                );
            case 401:
                if (errorResponse.error.exceptionCode === 'invalidToken') {
                    this.authService.logout();
                    this.toaster.error(
                        this.translateService.instant(
                            'ERROR.SESSION_INVALID_LOGIN_PLEASE',
                        ),
                    );
                }
                break;
            case 404:
                console.error(errorResponse);
                break;
            case 500: {
                const toasterErrorMsg = this.translateService.instant(
                    'ERROR.TRY_AGAIN_LATER',
                );
                this.toaster.error(toasterErrorMsg);
            }
        }

        return throwError(() => errorResponse || {});
    }

    private getRequestWithHeaders(req: HttpRequest<any>): HttpRequest<any> {
        let headers = req.headers.set('Accept', 'application/json');
        headers = headers.set('X-From-Benefit-Portal', '');
        headers = headers.set('X-Benefit-Portal-Module', this.module || '');

        if (
            !(
                this.tokenStorageService.getAccessTokenSync('Role-Name') ==
                    undefined ||
                this.tokenStorageService.getAccessTokenSync('Role-Name') ==
                    'undefined'
            )
        ) {
            headers = headers.set(
                'Role-Name',
                this.tokenStorageService.getAccessTokenSync('Role-Name'),
            );
        }

        if (
            !(
                this.tokenStorageService.getAccessTokenSync('Roleable-Id') ==
                    undefined ||
                this.tokenStorageService.getAccessTokenSync('Roleable-Id') ==
                    'undefined'
            )
        ) {
            headers = headers.set(
                'Roleable-Id',
                this.tokenStorageService.getAccessTokenSync('Roleable-Id'),
            );
        }

        if (
            !(
                this.tokenStorageService.getAccessTokenSync('Global-View') ==
                    undefined ||
                this.tokenStorageService.getAccessTokenSync('Global-View') ==
                    'undefined'
            )
        ) {
            headers = headers.set(
                'Global-View',
                this.tokenStorageService.getAccessTokenSync('Global-View'),
            );
        }

        if (this.tokenStorageService.checkAccessToken(this.tokenKey)) {
            headers = headers.set(
                'Authorization',
                `Bearer ${this.tokenStorageService.getAccessTokenSync(
                    this.tokenKey,
                )}`,
            );
        }
        let url = `${environment.apiUrl}`;
        if (!req.headers.has(SkipModuleUrl) && this.url) {
            url = `${url}${this.url}`;
        }
        url = `${url}/${req.url}`;
        return req.clone({
            url: url,
            headers: headers,
            withCredentials: true,
        });
    }

    private handleForbiddenError(error: HttpErrorResponse): Observable<any> {
        if (error.error.message === 'Wrong route') {
            return of(
                this.router.navigateByUrl(`${error.error.payload.redirect_to}`),
            );
        } else if (
            error.status === 403 &&
            error.error.payload.error === 'auth.inactive_company'
        ) {
            return this.authService.isAuthorized().pipe(
                map((authorized) => {
                    if (authorized) {
                        this.toaster.error(
                            this.translateService.instant(
                                'ERROR.COMPANY_INACTIVE_INFORM_THE_COMPANY_ADMINISTRATOR',
                            ),
                        );
                        this.authService.logout();
                    }
                    return new ForbiddenError();
                }),
            );
        } else {
            return this.authService.isAuthorized().pipe(
                map((authorized) => {
                    if (
                        !authorized &&
                        !this.authService.isLoginOrRegisterUrl()
                    ) {
                        this.authService.logout();
                    }
                    return new ForbiddenError();
                }),
            );
        }
    }
}
