import {
    IServerSideGetRowsRequest,
    LoadSuccessParams,
} from '@ag-grid-community/core';
import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
    HttpParams,
    HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ExportSettings } from '@app/contracts/contracts.service';
import { OfferAccessoryCategory } from '@app/offers/interfaces/offer-accessory-category.interface';
import { FILENAME_MATCH, FILENAME_REPLACE1 } from '@core/constants/regex';
import { saveAs } from '@core/helpers/file';
import { ResponseGeneric } from '@core/interfaces/response-generic.interface';
import { LoginMode } from '@core/services/auth/auth-settings.service';
import { SkipModuleUrl } from '@core/services/http/api.interceptor';
import { ProfileService } from '@core/services/profile/profile.service';
import { MODULE } from '@core/tokens/module.token';
import { TranslateService } from '@ngx-translate/core';
import { Factory } from '@shared/factory';
import { InsuranceRate } from '@shared/models/insurance-rate';
import { Offer, OfferStatus } from '@shared/models/offer';
import { Page } from '@shared/models/page';
import { ServiceRate } from '@shared/models/service-rate';
import { Status } from '@shared/models/status';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { OfferFormModel } from './offer-form-model';

export interface OfferListRequest {
    pageSize: number;
    page: number;
    order_by?: string;
    order?: string;
    status_id?: number[];
    search?: string;
    user_id?: number;
}

export interface OfferFilterRequest {
    status_id?: number;
    pageSize?: number;
    page?: number;
    order_by?: string;
    order?: string;
    contract_id?: number;
    order_id?: number;
}

export type ApproveOfferPayload = {
    offerId?: number;
    orderId?: number;
};

let baseUrl = '';

@Injectable({ providedIn: 'root' })
export class OffersService {
    constructor(
        private httpClient: HttpClient,
        private toastrService: ToastrService,
        private translateService: TranslateService,
        private profileService: ProfileService,
        @Inject(MODULE) private module: LoginMode,
    ) {
        baseUrl = `${this.module}/offers`;
    }

    public getPaginatedOffers(
        request: OfferListRequest,
    ): Observable<Page<Offer>> {
        const params = new HttpParams({
            fromObject: this.transformRequestToQuery(request),
        });
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        let url = 'offers';
        if (this.module === 'system') {
            url = `system/${url}`;
        }
        return this.httpClient.get<Page<Offer>>(url, { params, headers }).pipe(
            catchError((error: HttpErrorResponse) => {
                this.handleError(error);
                return throwError(() => error);
            }),
            map((response) => {
                const page = new Factory<Page<Offer>>(Page).fromObject(
                    response,
                );
                page.data = new Factory(Offer).fromArray(page.data);
                return page;
            }),
        );
    }

    public getStatuses(): Observable<Status[]> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        let url = `offers/statuses`;
        if (this.module === 'system') {
            url = `system/${url}`;
        }
        return this.httpClient.get<Status[]>(url, { headers }).pipe(
            map((res) => res),
            catchError((error: HttpErrorResponse) => {
                this.handleError(error);
                return throwError(() => error);
            }),
        );
    }

    public get(id: string | number): Observable<Offer> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        const url = `${baseUrl}/${id}`;
        return this.httpClient.get<Offer>(url, { headers }).pipe(
            catchError((error: HttpErrorResponse) => {
                this.handleError(error);
                return throwError(() => error);
            }),
            map((response) => new Factory(Offer).fromObject(response)),
        );
    }

    public create(
        offerFormModel: OfferFormModel,
        offer?: Offer,
    ): Observable<Offer> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        const url = offer ? `${baseUrl}/${offer.id}` : baseUrl;
        return this.httpClient
            .post<Offer>(url, offerFormModel, { headers })
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    this.handleError(error);
                    return throwError(() => error);
                }),
                map((response) => new Factory(Offer).fromObject(response)),
            );
    }

    public draft(
        offerFormModel: OfferFormModel,
        offer?: Offer,
    ): Observable<Offer> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        offerFormModel.statusId = OfferStatus.Draft;
        const url = offer ? `${baseUrl}/draft/${offer.id}` : `${baseUrl}/draft`;
        return this.httpClient
            .post<Offer>(url, offerFormModel, { headers })
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    this.handleError(error);
                    return throwError(() => error);
                }),
                map((response) => new Factory(Offer).fromObject(response)),
            );
    }

    public update(
        id: number,
        offerFormModel: OfferFormModel,
    ): Observable<Offer> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        return this.httpClient
            .post<Offer>(`${baseUrl}/${id}`, offerFormModel, { headers })
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    this.handleError(error);
                    return throwError(() => error);
                }),
                map((response) => new Factory(Offer).fromObject(response)),
            );
    }

    public accept(id: string | number): Observable<ResponseGeneric<Offer>> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        return this.httpClient
            .post<ResponseGeneric<Offer>>(`${baseUrl}/${id}/accept`, null, {
                headers,
            })
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    this.handleError(error);
                    return throwError(() => error);
                }),
            );
    }

    public acceptWithoutSigningUev(id: string | number): Observable<unknown> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        return this.httpClient
            .post(`${baseUrl}/${id}/accept-without-uev`, null, {
                headers,
            })
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    this.handleError(error);
                    return throwError(() => error);
                }),
            );
    }

    public reject(id: string | number): Observable<unknown> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        let url = `${baseUrl}/reject/${id}`;
        if (this.module === 'employee') {
            url = `${baseUrl}/${id}/reject`;
        }
        return this.httpClient.post(url, null, { headers });
    }

    public rejectWithReason(
        id: string | number,
        reason_text: string,
    ): Observable<unknown> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        return this.httpClient.post(
            `${baseUrl}/reject-with-reason/${id}`,
            { reason_text: reason_text },
            { headers },
        );
    }

    public temporaryReject(
        id: string | number,
        reason_text: string,
        company_change_id?: number,
    ): Observable<unknown> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        return this.httpClient.post(
            `${baseUrl}/temporary-reject/${id}`,
            { reason_text: reason_text, company_change_id: company_change_id },
            { headers },
        );
    }

    public approve(id: string | number): Observable<ApproveOfferPayload> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        return this.httpClient
            .post<ApproveOfferPayload>(`${baseUrl}/approve/${id}`, null, {
                headers,
            })
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    this.handleError(error);
                    return throwError(() => error);
                }),
            );
    }

    public downloadLeasingablesPdf(): void {
        const headers = new HttpHeaders();
        headers.set('Accept', 'application/json; charset=utf-8');
        headers.set('Content-Type', 'application/json; charset=utf-8');
        this.httpClient
            .post('settings/download-leasingable-pdf', null, {
                responseType: 'blob',
                headers: headers,
                observe: 'response',
            })
            .subscribe((res) => {
                const filename = res.headers
                    .get('content-disposition')
                    .match(FILENAME_MATCH)[1]
                    .replace(new RegExp(FILENAME_REPLACE1), '');
                saveAs(res.body, filename);
            });
    }

    public downloadSignedContract(
        id: string | number,
        isFullySigned = false,
    ): void {
        const url = isFullySigned
            ? `${baseUrl}/download-fully-signed-contract/${id}`
            : `${baseUrl}/download-signed-contract/${id}`;
        this.httpClient
            .get(url, {
                responseType: 'blob',
                headers: {
                    Accept: 'application/json; charset=utf-8',
                    'Content-Type': 'application/json; charset=utf-8',
                    'X-Skip-Module-Url': '',
                },
                observe: 'response',
            })
            .pipe(
                catchError((error) => {
                    console.error(error);
                    return throwError(() => error);
                }),
            )
            .subscribe((res) => {
                const filename = res.headers
                    .get('content-disposition')
                    .match(FILENAME_MATCH)[1]
                    .replace(new RegExp(FILENAME_REPLACE1), '');
                const pdfObject = {
                    body: res.body,
                    filename,
                };
                saveAs(pdfObject.body, pdfObject.filename);
            });
    }

    public downloadOfferPdf(id: string | number): void {
        this.httpClient
            .get(`${baseUrl}/${id}/offer-pdf`, {
                responseType: 'blob',
                headers: {
                    Accept: 'application/json; charset=utf-8',
                    'Content-Type': 'application/json; charset=utf-8',
                    'X-Skip-Module-Url': '',
                },
                observe: 'response',
            })
            .pipe(
                catchError((error) => {
                    console.error(error);
                    return throwError(() => error);
                }),
            )
            .subscribe((res) => {
                const filename = res.headers
                    .get('content-disposition')
                    .match(FILENAME_MATCH)[1]
                    .replace(new RegExp(FILENAME_REPLACE1), '');
                const pdfObject = {
                    body: res.body,
                    filename,
                };
                saveAs(pdfObject.body, pdfObject.filename);
            });
    }

    public downloadUploadedPdf(id: number): void {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        headers.set('Accept', 'application/json; charset=utf-8');
        headers.set('Content-Type', 'application/json; charset=utf-8');
        this.httpClient
            .get(`${baseUrl}/${id}/pdf`, {
                responseType: 'blob',
                headers: headers,
                observe: 'response',
            })
            .subscribe((res) => {
                const filename = res.headers
                    .get('content-disposition')
                    .match(FILENAME_MATCH)[1]
                    .replace(new RegExp(FILENAME_REPLACE1), '');
                saveAs(res.body, filename);
            });
    }

    public export(exportSettings: ExportSettings): Observable<boolean> {
        let url = 'offers/export';
        if (this.module === 'system') {
            url = `system/${url}`;
        }

        return this.httpClient
            .post(
                url,
                { exportSettings: exportSettings },
                {
                    responseType: 'blob',
                    headers: {
                        Accept: 'application/json; charset=utf-8',
                        'Content-Type': 'application/json; charset=utf-8',
                        'X-Skip-Module-Url': '',
                    },
                    observe: 'response',
                },
            )
            .pipe(
                tap((res: HttpResponse<Blob>) => {
                    const content = res.headers.get('content-disposition');
                    if (content) {
                        const filename = content
                            .match(FILENAME_MATCH)[1]
                            .replace(new RegExp(FILENAME_REPLACE1), '');
                        saveAs(res.body, filename);
                    }
                }),
                map(() => true),
                catchError(() => {
                    this.handleError(
                        new HttpErrorResponse({
                            error: { exceptionCode: 'exportFailed' },
                            status: 500,
                        }),
                    );
                    return of(false);
                }),
            );
    }

    public generateContract(
        id: string | number,
        formData: FormData,
    ): Observable<unknown> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        return this.httpClient.post(
            `${baseUrl}/${id}/generate-contract`,
            formData,
            { headers },
        );
    }

    public generateOptiVUContractPDF(
        id: number,
        token: string,
    ): Observable<unknown> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        const pdfUrl = `${baseUrl}/${id}/generate-contract-pdf?token=${token}`;
        return this.httpClient.get(pdfUrl, { headers });
    }

    public generateContractDigital(
        id: number,
    ): Observable<{ error: number; usermessage: string }> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        return this.httpClient
            .get<{
                error: number;
                usermessage: string;
            }>(`${baseUrl}/${id}/generate-contract-digital`, { headers })
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    this.handleError(error);
                    return throwError(() => error);
                }),
            );
    }

    public companyDigitalSignContract(
        id: string | number,
    ): Observable<unknown> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        return this.httpClient
            .get(`${baseUrl}/${id}/sign-contract-digital-company`, { headers })
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    this.handleError(error);
                    return throwError(() => error);
                }),
            );
    }

    public regenerateContract(
        id: string | number,
        formData: FormData,
    ): Observable<unknown> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        return this.httpClient.post(
            `${baseUrl}/${id}/regenerate-contract`,
            formData,
            { headers },
        );
    }

    public uploadSignedContract(
        id: string | number,
        formData: FormData,
    ): Observable<unknown> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        return this.httpClient.post(
            `${baseUrl}/${id}/upload-signed-contract-pdf`,
            formData,
            { headers },
        );
    }

    public updateRates(
        id: string | number,
        serviceRate: ServiceRate,
    ): Observable<Offer> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        return this.httpClient.put<Offer>(
            `${baseUrl}/${id}/rates`,
            { serviceRate },
            { headers },
        );
    }

    public updateInsuranceRates(
        id: string | number,
        insuranceRate: InsuranceRate,
    ): Observable<Offer> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        return this.httpClient.put<Offer>(
            `${baseUrl}/${id}/rates`,
            { insuranceRate },
            { headers },
        );
    }

    public updateSubsidyRates(
        id: string | number,
        selectedSubsidy: string | null,
    ): Observable<Offer> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        return this.httpClient.put<Offer>(
            `${baseUrl}/${id}/rates`,
            { selectedSubsidy },
            { headers },
        );
    }

    public cancel(data: Offer, link: string): Observable<Offer> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        return this.httpClient
            .post<Offer>(link, data, { headers })
            .pipe(map((offer) => new Factory(Offer).fromObject(offer)));
    }

    public cancelAsEmployee(id: string | number): Observable<Offer> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        return this.httpClient
            .post<Offer>(
                `${baseUrl}/${id}/cancel`,
                { cancellationReason: 'Cancelled by employee' },
                { headers },
            )
            .pipe(map((offer) => new Factory(Offer).fromObject(offer)));
    }

    public cancelAsSupplier(id: string | number): Observable<Offer> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        return this.httpClient
            .post<Offer>(
                `${baseUrl}/cancel/${id}`,
                { cancellationReason: 'Cancelled by supplier' },
                { headers },
            )
            .pipe(map((offer) => new Factory(Offer).fromObject(offer)));
    }

    public getFilteredOffers(
        request: OfferFilterRequest,
    ): Observable<Page<Offer>> {
        const params = new HttpParams({
            fromObject: this.transformRequestToQuery(request),
        });
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        let url = 'offers/filter';
        if (this.module === 'system') {
            url = `system/${url}`;
        }
        return this.httpClient.get<Page<Offer>>(url, { params, headers }).pipe(
            catchError((error: HttpErrorResponse) => {
                this.handleError(error);
                return throwError(() => error);
            }),
            map((response) => {
                const page = new Factory<Page<Offer>>(Page).fromObject(
                    response,
                );
                page.data = new Factory(Offer).fromArray(page.data);
                return page;
            }),
        );
    }

    public getAgGridData(
        request: IServerSideGetRowsRequest,
    ): Observable<LoadSuccessParams> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        const params = new HttpParams({
            fromObject: {
                globalView: String(this.profileService.global_view),
            },
        });
        let url = 'offers/aggrid';
        if (this.module === 'system') {
            url = `system/${url}`;
        }
        return this.httpClient
            .post<LoadSuccessParams>(url, request, { params, headers })
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    this.handleError(error);
                    return throwError(() => error);
                }),
            );
    }

    public getAllOfferAccessoryCategories(): Observable<
        OfferAccessoryCategory[]
    > {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');

        const url = `offers/getAllOfferAccessoryCategories`;
        return this.httpClient
            .get(url, { headers })
            .pipe(map((response) => response as OfferAccessoryCategory[]));
    }

    public getOfferAccessoryCategories(
        companyId: number,
    ): Observable<OfferAccessoryCategory[]> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');

        const url = `offers/getActiveCompanyOfferAccessoryCategories/${companyId}`;
        return this.httpClient.get<OfferAccessoryCategory[]>(url, { headers });
    }

    private transformRequestToQuery(
        request: OfferListRequest | OfferFilterRequest,
    ): { [key: string]: string } {
        const transformedObject: { [p: string]: string } = {};
        Object.keys(request).forEach((key) => {
            if (request[key]) {
                transformedObject[key] = request[key].toString();
            }
        });

        transformedObject['global_view'] = String(
            this.profileService.global_view,
        );

        return transformedObject;
    }

    private handleError(error: HttpErrorResponse): void {
        const errorMessages = new Map<string, string>([
            ['disableProductCategory', 'ERROR.PRODUCT_CATEGORY_DISABLED'],
            ['contractLimitReached', 'ERROR.CONTRACT_LIMIT_REACHED'],
            ['elvElsLimitReached', 'ERROR.ELV_ELS_LIMIT_REACHED'],
            ['exportFailed', 'ERROR.EXPORT_FAILED'],
            ['fieldValidationFailed', 'ERROR.FIELD_VALIDATION_FAILED'],
            ['inactivePortalStatus', 'ERROR.PORTAL_INACTIVE'],
            ['leasingBudgetReached', 'ERROR.LEASING_BUDGET_REACHED'],
            ['maximumPriceError', 'ERROR.MAXIMUM_OFFER_PRICE'],
            ['maxOfferAmountReached', 'ERROR.MAX_OFFER_AMOUNT_REACHED'],
            ['maximumUvpPriceError', 'ERROR.MAXIMUM_UVP_PRICE'],
            ['minimumPriceError', 'ERROR.MINIMUM_OFFER_PRICE'],
            ['missingPermissions', 'ERROR.PERMISSION'],
            ['noDistanceException', 'ERROR.NO_DISTANCE'],
            [
                'replacementContractPriceLow',
                'ERROR.REPLACEMENT_CONTRACT_PRICE_LOW',
            ],
            [
                'replacementContractTimeExpired',
                'ERROR.REPLACEMENT_CONTRACT_TIME_EXPIRED',
            ],
            ['userIsNotAllowed', 'ERROR.USER_NOT_ALLOWED'],
            ['userAttributeMissing', 'ERROR.USER_ATTRIBUTE_MISSING'],
        ]);
        let message: string;

        if (errorMessages.has(error.error?.exceptionCode)) {
            message = this.translateService.instant(
                errorMessages.get(error.error.exceptionCode),
            );
        } else if (
            error.error?.exceptionCode === 'insignError' &&
            error.error?.message
        ) {
            message = error.error.message;
        } else if (error.status === 422) {
            message =
                error.error?.message ??
                this.translateService.instant(
                    errorMessages.get('fieldValidationFailed'),
                );
        }

        if (message) {
            this.toastrService.error(message);
        }
    }
}
